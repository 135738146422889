<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="offset-ms3"
        cols="12"
        sm="8"
        offset-sm="2"
        md="6"
        lg="4"
        offset-lg="4"
      >
        <v-form
          ref="passwordForm"
          v-model="valid"
          @submit.prevent="submitForm"
        >
          <material-card
            color="blue"
            :title="title"
          >
            <v-alert
              class="text-left"
              transition="scale-transition"
              :value="alert"
              :type="alert.type"
            >
              {{ alert.message }}
            </v-alert>
            <template v-if="success">
              <p>{{ success.message }}</p>
              <v-btn
                color="info"
                block
                dark
                to="/login"
              >
                {{ success.button }}
              </v-btn>
            </template>
            <template v-else-if="this.$route.params.token">
              <v-card-text>
                <p>Please enter your email and a new password</p>
                <v-text-field
                  v-model="form.email"
                  :label="$t('email')"
                  name="email"
                  type="email"
                  required
                  prepend-icon="mdi-email-outline"
                  :rules="rules.email"
                />
                <v-text-field
                  v-model="form.password"
                  label="New Password"
                  name="password"
                  required
                  counter
                  minlength="8"
                  prepend-icon="mdi-fingerprint"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  hint="At least 8 characters"
                  :rules="rules.password"
                  @click:append="show_password = !show_password"
                />
                <v-text-field
                  v-model="form.password_confirmation"
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  required
                  minlength="8"
                  prepend-icon="mdi-fingerprint"
                  :rules="rules.password_confirmation"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="info"
                  block
                  dark
                  type="submit"
                  :loading="loading"
                >
                  Set Password
                </v-btn>
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-text>
                <p>{{ $t('auth.password.instructions') }}</p>
                <v-text-field
                  v-model="form.email"
                  :label="$t('email')"
                  name="email"
                  type="email"
                  required
                  prepend-icon="mdi-email-outline"
                  :rules="rules.email"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="info"
                  block
                  dark
                  type="submit"
                  :loading="loading"
                >
                  {{ $t('auth.password.email_button') }}
                </v-btn>
              </v-card-actions>
            </template>
          </material-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        title: this.$t('Reset Password'),
        success: null,
        valid: false,
        loading: false,
        alert: false,
        show_password: false,
        form: {
          email: null,
          password: null,
          token: this.$route.params.token,
        },
        rules: {
          email: [
            v => !!v || 'Please enter your e-mail',
            v => /.+@.+/.test(v) || 'Please enter a valid e-mail address',
          ],
          password: [
            v => !!v || 'Please enter your password',
            v => v?.length >= 8 || 'Password must have a minimum of 8 characters',
          ],
          password_confirmation: [
            v => !!v || 'Please re-enter your password',
            v => v === this.form.password || 'Passwords must match',
          ],
        },
      }
    },
    methods: {
      submitForm () {
        if (this.valid) {
          this.loading = true
          const promise = this.axios.post(this.$route.params.token ? 'auth/reset' : 'auth/password', this.form)
          return promise.then(() => {
            this.alert = false
            if (this.$route.params.token) {
              this.title = 'Success!'
              this.success = {
                message: 'Your password has been reset',
                button: 'Log In',
              }
            } else {
              this.title = 'Email Sent'
              this.success = {
                message: 'We have sent an email to ' + this.form.email + ' with a link to reset your password.',
                button: 'Back to Login',
              }
            }
          }).catch(error => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.alert = {
                    type: 'error',
                    message: 'Invalid email address',
                  }
                  break
                case 412:
                  this.alert = {
                    type: 'error',
                    message: 'The token is no longer valid',
                  }
                  break
                default:
                  this.alert = {
                    type: 'error',
                    message: error,
                  }
                  break
              }
            }
            return false
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.$refs.passwordForm.validate()
        }
      },
    },
  }
</script>

<style scoped>
#logo {
  margin: 20px;
}
</style>
